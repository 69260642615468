<template>
  <div class="pageContol startClassRecord">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()" class="cur-a">机构列表</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">开班记录</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="seachStyle">
          <el-row :gutter="20">
            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <span class="listLabel">班级编码:</span>
              <el-input
                class="listInput"
                v-model="searchData.projectCode"
                placeholder="请输入班级编码"
                size="small"
                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                clearable
              />
            </el-col>
            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <span class="listLabel">班级名称:</span>
              <el-input
                class="listInput"
                v-model="searchData.projectName"
                placeholder="请输入班级名称"
                size="small"
                clearable
              />
            </el-col>
            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <span class="listLabel">行政区划:</span>
              <el-cascader
                clearable
                v-model="searchData.areaId"
                :options="areatreeList"
                :props="propsarea"
                size="small"
                class="listInput"
              ></el-cascader>
            </el-col>
            <el-col :sm="12" :md="8" :lg="5" class="searchList">
              <span class="listLabel">班级状态:</span>
              <el-select
                class="listInput"
                size="small"
                v-model="searchData.classStatus"
                placeholder="请选择班级状态"
                clearable
              >
                <el-option
                  v-for="item in classStatusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :sm="12" :md="12" :lg="8" class="searchList">
              <span class="listLabel">开始时间:</span>
              <el-date-picker
                v-model="searchData.opentime"
                type="daterange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
                clearable
                class="listInput"
              />
            </el-col>

            <el-col :sm="12" :md="4" :lg="6" class="searchList">
              <div class="listBtn">
                <el-button type="primary" class="listBtnS" round @click="getData()">查询</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="序号" align="center" type="index" :index="indexMethod" />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                minWidth="150px"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                minWidth="150px"
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaNamePath"
                minWidth="120px"
              />
              <el-table-column label="负责人" align="left" show-overflow-tooltip prop="projectUser" />
              <el-table-column
                label="负责人电话"
                align="left"
                show-overflow-tooltip
                prop="projectUserPhone"
                minWidth="100px"
              />
              <el-table-column
                label="职业/工种"
                align="left"
                show-overflow-tooltip
                prop="occupationName"
              />
              <el-table-column
                label="培训等级"
                align="left"
                show-overflow-tooltip
                prop="trainLevelName"
              />
              <el-table-column label="学员数" align="right" show-overflow-tooltip prop="userNum" />
              <el-table-column
                label="学习完成率"
                align="right"
                show-overflow-tooltip
                prop="percentageComplete"
              />
              <el-table-column
                label="开始时间"
                align="left"
                show-overflow-tooltip
                prop="startDate"
                minWidth="100px"
              ></el-table-column>
              <el-table-column
                label="结束时间"
                align="left"
                show-overflow-tooltip
                prop="endDate"
                minWidth="100px"
              ></el-table-column>
              <el-table-column
                label="班级状态"
                align="left"
                show-overflow-tooltip
                prop="projectState"
              >
                <template slot-scope="scope">
                  <span>
                    {{
                    $setDictionary("PROJECTSTATE", scope.row.projectState)}}
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" width="200px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button type="text" size="mini" @click="open(scope.row.projectId,1)">学员</el-button>
                  <el-button type="text" size="mini" @click="showDocsDialog(scope.row)">学员档案</el-button>
                  <el-button type="text" size="mini" @click="open(scope.row.projectId,2)">班级档案</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <el-dialog
      title="培训档案选择"
      :visible.sync="docsDialogVisible"
      width="50%"
      top="5%"
      :center="true"
      :before-close="closeDocsDialog"
    >
      <div :style="trainTypeId == 11 ? 'height:500px': ''">
        <div class="ovy-a">
          <div class="downloadStyle">
            <el-form  ref="docsForm" :model="docsForm" class="docsDialog">
              <el-form-item v-for="(val, key) in docsOptions" :key="key">
                <el-checkbox v-model="docsForm[key]">{{val}}</el-checkbox>
              </el-form-item>
            </el-form>
            <el-form
             
              ref="docsForms"
              :model="docsForm"
              class="docsDialog"
              v-show="trainTypeId == 5 && dayTure!=false "
            >
              <el-form-item v-for="(val, key) in docsOptionss" :key="key">
                <el-checkbox v-model="docsForms[key]">{{val}}</el-checkbox>
              </el-form-item>
            </el-form>
            <el-form
             
              ref="docsFormall"
              :model="docsFormall"
              class="docsDialog"
              v-show="trainTypeId == 11"
            >
              <el-form-item v-for="(val, key) in docsOptionall" :key="key">
                <el-checkbox v-model="docsFormall[key]">{{val}}</el-checkbox>
              </el-form-item>
            </el-form>
          </div>
          <el-checkbox v-model="agreeState" style="margin: 0 3%;">
            <span style="color:red!important;">
              根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，
              不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。我已阅读并同意上述内容。
            </span>
          </el-checkbox>
        </div>
      </div>
      <span slot="footer" class="dialog-footer" style="display: flex; justify-content: center;">
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
const className = "eduCertService";
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "sjzRegulator/startClassRecord",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      searchData: {
        projectCode: "", //班级编码
        projectName: "", //班级名称
        opentime: "", //开始时间
        areaId: "", //行政区划
        classStatus: "" //班级状态
      },
      classStatusList: [
        {
          value: "",
          label: "全部"
        },
        {
          value: "30",
          label: "已通过"
        },
        {
          value: "50",
          label: "培训中"
        },
        {
          value: "60",
          label: "培训结束"
        }
      ], //班级状态list
      areatreeList: [], //行政区域list
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      docsForm: {},
      docsForms: {},
      docsFormall: {},
      selectedItem: {},
      docsDialogVisible: false,
      agreeState: false, //弹框是否同意
      docsOptions: {
        "10": "学时证明",
        "20": "学员培训档案",
        "40": "培训证书",
        "70": "学员统计情况总表"
      },
      docsOptionss: {
        "50": "职业提升培训线上学习情况审核"
      },
      docsOptionall: {
        "10": "全员培训档案首页",
        "15": "生产经营单位新入职从业人员登记表",
        "20": "厂（矿）级安全培训考核记录",
        "25": "车间（工段、区、队）级安全培训考核记录",
        "30": "班组级安全培训考核记录",
        "35": "一般行业生产经营单位新入职从业人员安全培训考核记录",
        "40": "生产经营单位从业人员年度安全再培训考核记录",
        "45": "生产经营单位从业人员调岗和重新上岗安全培训考核记录(车间级)",
        "50": "生产经营单位从业人员调岗和重新上岗安全培训考核记录(班组级)",
        "55": "生产经营单位从业人员“四新”安全培训考核记录",
        "60": "生产经营单位从业人员专项安全培训考核记录",
        "65": "特种作业人员培训信息登记及证件真伪核查表",
        "70": "生产经营单位从业人员安全生产奖惩记录",
        "1000": "全员培训档案模版"
      }
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser"
    })
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getareatree();
      this.getTableHeight();
      this.getData(-1);
    },
    //跳转开班统计
    open(projectId, type) {
      if (type == "1") {
        //学员
        this.$router.push({
          path: "/web/SupervisionEnd/sjzRegulator/studentList",
          query: {
            projectId: projectId
          }
        });
      }
      if (type == "2") {
        //班级档案
        this.$router.push({
          path: "/web/SupervisionEnd/sjzRegulator/classRecord",
          query: {
            projectId: projectId,
            stu: "view",
            stus:'details'
          }
        });
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageSize: this.pageSize,
        compId: this.$route.query.compId
      };

      if (this.searchData.projectCode) {
        params.projectCode = this.searchData.projectCode;
      }
      if (this.searchData.projectName) {
        params.projectName = this.searchData.projectName;
      }
      if (this.searchData.opentime && this.searchData.opentime.length > 0) {
        params.startTime = this.searchData.opentime[0];
        params.endTime = this.searchData.opentime[1];
      }
      if (this.searchData.areaId) {
        params.areaId = this.searchData.areaId;
      }
      if (this.searchData.classStatus) {
        params.trainState = this.searchData.classStatus;
      }

      this.doFetch({
        url: "/gov/roll/call/project/page-list",
        params,
        pageNum
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/gov/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    showDocsDialog(raw) {
      this.docsDialogVisible = true;

      this.selectedItem.id = raw.projectId;
      this.selectedItem.name = raw.projectName;
      this.trainTypeId = raw.trainTypeId;
      this.day = raw.endDate;
      this.day = raw.endDate;
      let day = this.day ? this.day.slice(0, 10) : "";
      let curDay = this.$moment().format("YYYY/MM/DD");
      if (curDay > day) {
        this.dayTure = true;
      }
    },
    confirmDocsDialog() {
      if (!this.agreeState) {
        this.$message({
          type: "warning",
          message: "请先阅读提示内容并同意"
        });
        return;
      }
      let params = {
        fileDTOS: []
      };
      for (let i in this.docsForm) {
        if (this.docsForm[i]) {
          params.fileDTOS.push({
            fileType: i,
            fileName: this.selectedItem.name + "-" + this.docsOptions[i],
            className: className,
            param: {
              projectId: this.selectedItem.id
            }
          });
        }
      }
      for (let i in this.docsForms) {
        if (this.docsForms[i]) {
          params.fileDTOS.push({
            fileType: i,
            fileName: this.selectedItem.name + "-" + this.docsOptionss[i],
            className: className,
            param: {
              projectId: this.selectedItem.id
            }
          });
        }
      }
      let examineTypes = [];
      for (let i in this.docsFormall) {
        if (this.docsFormall[i]) {
          examineTypes.push(i);
        }
      }
      if (examineTypes.length != 0) {
        params.fileDTOS.push({
          fileType: "30",
          fileName: this.selectedItem.name + "-全员培训档案",
          className: "eduProjectUserService",
          param: {
            projectId: this.selectedItem.id,
            examineTypes: examineTypes
          }
        });
      }
      if (!params.fileDTOS.length) {
        this.$message.error("请至少选择一项进行打印");
        return;
      }
      // if (!params.fileDTOSAll.length) {
      //   this.$message.error("请至少选择一项进行打印");
      //   return;
      // }

      this.$post("/sys/download/asyncDownloadFile", params).then(re => {
        if (re.status == "0") {
          let list = re.data;
          for (let item of list) {
            if (!this.downloadItems.includes(item.taskId)) {
              this.$store.dispatch("pushDownloadItems", item.taskId);
            } else {
              this.$message.warning(
                "[" + item.fileName + "]已经申请下载,请耐心等待"
              );
            }
          }
          this.closeDocsDialog();
        } else {
          this.$message.error(re.message);
        }
      });
    },
    closeDocsDialog() {
      this.docsDialogVisible = false;
      this.agreeState = false;
      this.dayTure = false;
      this.$refs["docsForm"].resetFields();
      this.$refs["docsForms"].resetFields();
      this.$refs["docsFormall"].resetFields();
      for (let key in this.docsForm) {
        this.docsForm[key] = false;
      }
      for (let key in this.docsForms) {
        this.docsForms[key] = false;
      }
      for (let key in this.docsFormall) {
        this.docsFormall[key] = false;
      }
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less" scoped>
.el-checkbox {
  display: flex;
  white-space: pre-wrap !important;
  text-align: justify;
}
.downloadStyle {
  background-color: #f0f5fb;
  padding: 20px 0;
  margin-bottom: 30px;
  overflow: hidden;
}
.docsDialog {
  .el-form-item {
    width: 44%;
    float: left;
    margin-bottom: 0.5rem;
    margin: 10px 3%;
  }
}
</style>
